/*=========================
    19 FAQ
===========================*/
.faq {
  background: var(--color-white2);
}
.faq .section-title h6 {
  padding: 0px 30px 10px 30px;
}
.faq .left-content {
  padding-right: 50px;
}
.faq .left-content p {
  margin-top: 20px;
}
.faq .left-content .link-anime {
  margin-top: 30px;
}
.faq .right-countent ul {
  margin-top: 30px;
}
.faq .right-countent li ~ li {
  xmargin-top: 15px;
}
.faq .right-countent button {
  position: relative;
  font-family: "Arima Madurai", cursive;
  width: 100%;
  padding: 20px 50px 20px 20px;
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: inherit;
  border-radius: 5px;
  border: 1px solid var(--color-border);
  background: var(--color-white);
}
.faq .right-countent button span {
  display: inline-block;
}
.faq .right-countent button .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.faq .right-countent button .icon svg {
  fill: var(--color-yellow);
  --svg-font-size: 16px;
  margin-top: 0;
}
.faq .right-countent button.collapsed .icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.faq .right-countent button.collapsed .icon svg {
  fill: var(--color-black);
}
.faq .right-countent .box-content {
  xadding: 30px;
  border: 1px solid var(--color-border);
  border-top: none;
  background: var(--color-white);
}
