:root {
  /**
    * colors
    */

  --dark-jungle-green: hsl(188, 63%, 7%);
  --prussian-blue: hsl(200, 69%, 14%);
  --raisin-black-1: hsl(227, 29%, 13%);
  --raisin-black-2: hsl(229, 17%, 19%);
  --yellow-green: hsl(89, 72%, 45%);
  --orange-soda: hsl(9, 100%, 62%);
  --cultured-1: hsl(0, 0%, 93%);
  --cultured-2: hsl(192, 24%, 96%);
  --misty-rose: hsl(7, 56%, 91%);
  --alice-blue: hsl(210, 100%, 97%);
  --seashell: hsl(8, 100%, 97%);
  --cadet: hsl(200, 15%, 43%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --opal: hsl(180, 20%, 62%);

  /**
    * typography
    */

  --ff-nunito-sans: "Nunito Sans", sans-serif;
  --ff-poppins: "Poppins", sans-serif;

  --fs-1: 1.875rem;
  --fs-2: 1.5rem;
  --fs-3: 1.375rem;
  --fs-4: 1.125rem;
  --fs-5: 0.875rem;
  --fs-6: 0.813rem;
  --fs-7: 0.75rem;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
    * transition
    */

  --transition: 0.25s ease;

  /**
    * spacing
    */

  --section-padding: 100px;

  /**
    * shadow
    */

  --shadow-1: 0 5px 20px 0 hsla(219, 56%, 21%, 0.1);
  --shadow-2: 0 16px 32px hsla(188, 63%, 7%, 0.1);
}

a,
img,
span,
button,
ion-icon {
  display: block;
}

button {
  border: none;
  background: none;
  font: inherit;
  text-align: left;
  cursor: pointer;
}

address {
  font-style: normal;
}

ion-icon {
  pointer-events: none;
}

.header-bottom-actions {
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-block: 15px 10px;
  box-shadow: -2px 0 30px hsla(237, 71%, 52%, 0.2);
  z-index: 3;
}

.header-bottom-actions-btn ion-icon {
  color: hsl(0, 0%, 10%);
  font-size: 20px;
  margin-inline: auto;
  margin-bottom: 5px;
  --ionicon-stroke-width: 40px;
  transition: var(--transition);
}

.header-bottom-actions-btn:is(:hover, :focus) ion-icon {
  color: var(--orange-soda);
}

.header-bottom-actions-btn span {
  color: var(--cadet);
  font-family: var(--ff-poppins);
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}

/*-----------------------------------*\
   #MEDIA QUERIES
 \*-----------------------------------*/

/**
  * responsive for larger than 600px screen
  */

@media (min-width: 600px) {
  /**
    * CUSTOM PROPERTY
    */

  :root {
    /**
      * typography
      */

    --fs-2: 1.875rem;
  }

  /**
    * REUSED STYLE
    */

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

  .has-scrollbar {
    gap: 25px;
    margin-inline: -25px;
    padding-inline: 25px;
    scroll-padding-left: 25px;
  }

  .has-scrollbar > li {
    min-width: calc(50% - 12.5px);
  }
}

/**
  * responsive for larger than 768px screen
  */

@media (min-width: 768px) {
  /**
    * CUSTOM PROPERTY
    */

  :root {
    /**
      * typography
      */

    --fs-1: 2.5rem;
    --fs-5: 0.938rem;
    --fs-6: 0.875rem;
  }

  /**
    * REUSED STYLE
    */

  .container {
    max-width: 720px;
  }

  .btn {
    --fs-5: 1rem;
    padding: 12px 28px;
  }

  /**
    * HEADER
    */

  .header-top {
    padding-block: 5px;
  }

  .header-top .wrapper {
    margin-left: auto;
  }

  .header-top-social-list {
    gap: 12px;
  }

  .header-top-social-link {
    font-size: 1rem;
  }

  .header-top-btn {
    padding: 10px 20px;
  }

  .header-bottom-actions {
    all: unset;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-bottom-actions-btn ion-icon {
    margin-bottom: 0;
  }

  .header-bottom-actions-btn span {
    display: none;
  }

  .header-bottom-actions-btn {
    background: var(--white);
    width: 50px;
    height: 50px;
    box-shadow: var(--shadow-2);
  }
}

/**
  * responsive for larger than 992px screen
  */

@media (min-width: 992px) {
  /**
    * CUSTOM PROPERTY
    */

  :root {
    /**
      * typography
      */

    --fs-1: 3.125rem;
    --fs-4: 1.375rem;
  }

  /**
    * REUSED STYLE
    */

  .container {
    max-width: 970px;
  }

  .btn {
    padding: 15px 40px;
  }

  /**
    * HEADER
    */

  .header-top-list,
  .header-top .wrapper {
    gap: 30px;
  }
}

/**
  * responsive for larger than 1200px screen
  */

@media (min-width: 1200px) {
  /**
    * CUSTOM PROPERTY
    */

  :root {
    /**
      * typography
      */

    --fs-2: 2.75rem;
    --fs-4: 1.5rem;
  }

  /**
    * REUSED STYLE
    */

  .container {
    max-width: 1200px;
  }

  .has-scrollbar > li {
    min-width: calc(33.33% - 16.66px);
  }

  /**
    * HEADER
    */

  .header-bottom {
    padding-block: 30px;
  }

  .header-bottom-actions-btn:last-child,
  .navbar-top,
  .overlay {
    display: none;
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .navbar-link {
    color: var(--dark-jungle-green);
    --fs-5: 1.125rem;
    text-transform: capitalize;
  }

  .header {
    padding-bottom: 114px;
  }

  .header-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .header.active .header-bottom {
    position: fixed;
    bottom: auto;
    top: -94px;
    padding-block: 20px;
    box-shadow: 0 10px 50px hsla(237, 71%, 52%, 0.2);
    animation: slideDown 0.25s ease-out forwards;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }

  /**
    * HERO
    */

  .hero-text {
    padding-left: 30px;
    max-width: 450px;
    margin-bottom: 40px;
  }
}
