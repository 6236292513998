.hero-swiper {
  width: 100%;
  height: 100%;
}

.hero-swiper-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
