/* =================
    Root Color 
=================*/
:root {
  --color-white: #ffffff;
  --color-white2: #f9f6f2;
  --color-white3: #e2d9d2;
  --color-black: #2b221f;
  --color-black2: #201a18;
  --color-black3: #3f3532;
  --color-gBlack: #50413c;
  --color-gBlack2: #aca5a3;
  --color-yellow: #fcc41a;
  --color-green: #639533;
  --color-border: #e5e1dd;
  --color-fb: #3b5998;
  --color-tw: #00acee;
  --color-pin: #c8232c;
  --color-in: #8a3ab9;
  --color-tr: transparent;
}

@media (min-width: 768px) {
  /*=========================
      19 FAQ
  ===========================*/
  .faq .right-countent ul {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  /*=========================
      19 FAQ
  ===========================*/
  .faq .left-content .link-anime {
    margin-top: 40px;
  }
  .faq .right-countent li ~ li {
    margin-top: 20px;
  }
  .faq .right-countent button {
    padding: 30px 70px 30px 30px;
    font-size: 20px;
    line-height: 30px;
  }
  .faq .right-countent button .icon {
    top: 30px;
    right: 30px;
  }
  .faq .right-countent button .icon svg {
    --svg-font-size: 20px;
  }
  .faq .right-countent .box-content {
    padding: 30px;
  }
}

@media all and (max-width: 30em) {
  .col-lg-2 {
    display: block;
    margin: 0.4em auto;
    width: 100%;
  }
  .cont-tem {
    margin-top: 20px;
    display: inline;
  }
}
