@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Exo", sans-serif;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: medium;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.indigobgmedium {
  background-color: #4c39d4;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
}

.landScreen {
  background: #f5f7fa;
  padding: 0rem 0rem;
}
.containerPage {
  background: rgba(0, 0, 0, 0.359);
  height: 100vh;
  padding: 0.5rem 2rem;
}
.landIconImg {
  width: 100%;
  text-align: center;
  opacity: 0.8;
}
.navImageTab .landIconImg {
  filter: brightness(0) invert(1);
}
.headerback {
  background: rgba(0, 0, 0, 0.058);
}
.headerbackblue {
  background: #ffffffde;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.179);
}

.backButton:hover {
  background: #4b39d498;
}

.SamIcon {
  height: 15px;
  width: 15px;
  opacity: 0.8;
}
.SideNav .SamIcon {
  height: 25px;
  width: 25px;
}

.SideNav span {
  font-size: 0.1rem;
}

.SideNavTitle span {
  font-size: 0.8rem;
}

span {
  font-size: 1rem;
}

.custom-backface-visibilty {
  transition: all 0.7s ease-in-out;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: #cbd5e0;
}

.scrollbar-thin {
  scrollbar-color: #4a5568 #cbd5e0;
  scrollbar-width: thin;
}

.hero {
  background-image: linear-gradient(rgb(9, 58, 122, 0.9), rgb(9, 58, 122, 0.8)),
    url("../photo/consultant.jpg");

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.calendar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.664), rgba(0, 0, 0, 0.904)),
    url("../photo/calendar.jpg");

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.ourTeam {
  background-image: linear-gradient(
      rgba(11, 84, 219, 0.8),
      rgba(11, 84, 219, 0.8)
    ),
    url("../photo/teamwork.jpg");

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.venueImage {
  background-image: linear-gradient(
      rgba(11, 84, 219, 0.8),
      rgba(11, 84, 219, 0.8)
    ),
    url("../photo/meetingRoom1.jpg");

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.consultantImage {
  background-image: linear-gradient(
      rgba(11, 84, 219, 0.8),
      rgba(11, 84, 219, 0.8)
    ),
    url("../photo/meetingRoom3.jpg");

  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  color: #000;
  display: flex;
}

.VIpgJd-ZVi9od-ORHb {
  margin: 0;
  background-color: #e4effb;
  overflow: hidden;
  display: none;
}

/* @layer utilities {
  .CustomtextSizeTitle {
    font-size: calc(34px + (41 - 34) * ((100vw - 480px) / (960 - 480)));
    font-size: 2rem;
    line-height: 1.37;
  }

  .CustomtextSizeSubTitle {
    font-size: calc(34px + (41 - 34) * ((100vw - 480px) / (960 - 480)));
    font-size: 1.3rem;
    line-height: 1.4;
  }

  .CustomtextSize{
    font-size: 1.2rem;
    line-height: 1.8;
  }

  .CustomtextSizeFooter{
    font-size: 1rem;
    line-height: 1;
  }
} */
